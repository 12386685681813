$panel-bg: #95deff;
$panel-drop: 5px 5px #7ecff4;
$panel-light: #cbecff;

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ease-in-desc {
  0% {
    display: none;
    background-color: rgba(0, 0, 0, 0);
  }

  1% {
    display: block;
  }

  100% {
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

@keyframes ease-out-desc {
  0% {
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
  }

  99% {
    display: none;
  }

  100% {
    display: none;
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes ease-in-desc-text {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ease-out-desc-text {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    margin-top: 1em;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

hr {
  width: 80%;
  border-style: none;
  border: solid grey 0.5px;
}

/* Experimental? */

.cont {
  display: none;
}

.ioswarning {
  display: none;
}

html {
  width: 100%;
  height: 100%;
}

.nav {
  ul {
    width: 100%;
    display: flex;
    list-style-type: none;
    justify-content: flex-end;
    align-content: flex-end;
    li {
      margin: 1vh;
      padding: 0.6vh 0.6vh 0.4vh 0.6vh;
    }
    li:hover {
      background: $panel-light;
    }
  }
}
.dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.7vh;
  bottom: 5vh;
  right: 0;
  display: none;
  position: absolute;
  background-color: #cbecff;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0.5em;
}

.dropdown-content > div {
  padding: 0.5em;
  cursor: pointer;
}

.dropdown-content > div > p {
  margin: 0;
}

.dropdown-content > ul > a,
.dropdown-content > div > a {
  margin: 0;
  color: #334;
  text-decoration: none;
}

.dropdown-content > p {
  margin: 0.2em;
}

.dropdown-content > ul {
  margin: 0;
  padding: 0;
}

.dropdown-content > ul > a > li,
.dropdown-content > ul > li {
  padding: 0.5em;
  list-style: none;
}

.dropdown-content > ul > a > li:hover,
.dropdown-content > div:hover {
  background: $panel-bg;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.show {
  display: block;
}

.small-meta {
  text-align: center;
  flex-grow: 2;
  overflow: hidden;
}

.small-meta > p {
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.spinme {
  -webkit-animation: spin 0.5s linear infinite;
  -moz-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
}

.nowplayingtext {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: desc;
  padding-left: 1.5em;
  padding-right: 1.5em;
  background: $panel-bg;
  box-shadow: $panel-drop;
  color: #334;
}

.nowplayingtext > p {
  margin: 1vh 0;
}

body {
  margin: 0;
  background: #82d7ff;
  font-family: "Ubuntu", sans-serif !important;
}

#homegrid {
  background: #82d7ff;
  max-width: 100vw;
}

.art {
  grid-area: art;
  display: flex;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
}

.art > img {
  object-fit: contain;
}

.logo,
.logo-mobile {
  grid-area: logo;
  margin: 1vh;
  background-image: url(logo-full.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo > img {
  width: auto;
  height: 100%;
}

.nav {
  display: flex;
  grid-area: nav;
}
.nav > ul > li {
  background: $panel-bg;
  box-shadow: $panel-drop;
}

.btn {
  border-radius: 0 !important;
}

.nullSpace {
  flex-grow: 3;
}

.control {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  flex-grow: initial;
  grid-area: control;
  padding-left: 1.5em;
  background: $panel-bg;
  box-shadow: $panel-drop;
  color: #334;
}
.control > * {
  margin-right: 1.5em;
}

.control > input,
.control > .dropdown > input {
  height: 5vh;
}

.djname {
  font-size: 1.3vw;
}

.banner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  grid-area: banner;
  padding-left: 1.5em;
  background: $panel-bg;
  box-shadow: $panel-drop;
  color: #334;
}

.purchase a {
  display: flex;
  align-self: flex-start;
  justify-content: left;
  align-items: center;
  text-align: left;
  text-decoration: none;
  color: #334;
}

.played-1 {
  grid-area: played-1;
}

.played-2 {
  grid-area: played-2;
}
.played-3 {
  grid-area: played-3;
}
.played-4 {
  grid-area: played-4;
}

.option-1 {
  grid-area: played-1;
}
.option-2 {
  grid-area: played-2;
}
.option-3 {
  grid-area: played-3;
}
.option-4 {
  grid-area: played-4;
}

.option-1,
.option-2,
.option-3,
.option-4 {
  position: absolute;
  z-index: 1;
  right: 2px;
  top: 2px;
  opacity: 0.4;
}

.played-1,
.played-2,
.played-3,
.played-4 {
  display: flex;
  position: relative;
  background: $panel-bg;
  box-shadow: $panel-drop;
  overflow: hidden;
  align-items: center;
  padding: 5px;
}

.option-1:hover,
.option-2:hover,
.option-3:hover,
.option-4:hover,
.played-1:hover + .option-1,
.played-2:hover + .option-2,
.played-3:hover + .option-3,
.played-4:hover + .option-4 {
  opacity: 1;
}

.played-1 > img,
.played-2 > img,
.played-3 > img,
.played-4 > img {
  vertical-align: middle;
  object-fit: contain;
  max-height: 10vh;
  height: 100%;
  max-width: 96px;
}

.played-1 > .text,
.played-2 > .text,
.played-3 > .text,
.played-4 > .text {
  flex-grow: 4;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
}

.played-1 > .option,
.played-2 > .option,
.played-3 > .option,
.played-4 > .option {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
}

.played-1:hover > .option,
.played-2:hover > .option,
.played-3:hover > .option,
.played-4:hover > .option {
  display: block;
}

@media only screen and (min-width: 1025px) {
  .homegrid {
    top: 0;
    position: absolute;
    display: grid;
    padding: 1em;
    height: 100%;
    width: 100vw;
    box-sizing: border-box;
    grid-gap: 1em;
    grid-template-rows: 1fr 0.5fr 0.5fr 3fr 1fr 0.5fr 0.5fr 1fr;
    grid-template-columns: 0.5fr 2fr 2fr 2fr 2fr 0.5fr;
    grid-template-areas:
      ".        logo        .           nav         nav         ."
      ".        .           .           .           .           ."
      ".        art         art         .           .           ."
      ".        art         art         desc        desc        ."
      ".        art         art         control     control     ."
      ".        art         art         .           .           ."
      ".        .           .           .           .           ."
      ".        played-1    played-2    played-3    played-4    .";
  }

  .live {
    grid-template-rows: 1fr 0.5fr 1fr 3fr 1fr 0.5fr 1fr !important;
    grid-template-areas:
      ".        logo        .           nav         nav         ."
      ".        .           .           .           .           ."
      ".        art         art         banner      banner      ."
      ".        art         art         desc        desc        ."
      ".        art         art         control     control     ."
      ".        .           .           .           .           ."
      ".        played-1    played-2    played-3    played-4    ." !important;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .homegrid {
    display: grid;
    padding: 1em;
    height: 100vh;
    box-sizing: border-box;
    grid-gap: 1em;
    grid-template-rows: 1fr 0.5fr 0.5fr 3.5fr 1fr 0.5fr 1fr;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-template-areas:
      "logo         .           nav         nav"
      ".            .           .           ."
      "art          art         .           ."
      "art          art         desc        desc"
      "art          art         control     control"
      ".            .           .           ."
      "played-1     played-2    played-3    played-4";
  }
  .homegrid.mobile {
    position: absolute;
    display: grid;
    height: 100%;
    width: 100vw;
  }
  .live {
    grid-template-rows: 1fr 0.5fr 1fr 3.5fr 0.5fr 0.5fr 1fr !important;
    grid-template-areas:
      "logo         .           nav         nav"
      ".            .           .           ."
      "art          art         .           ."
      "art          art         desc        desc"
      "art          art         desc        desc"
      ".            .           .           ."
      "played-1     played-2    played-3    played-4" !important;
  }
  .logo {
    margin: 0.5vh;
  }

  .art-mobile {
    display: flex;
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: span 8;
    align-items: center;
  }

  .art-mobile > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .logo-mobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
  html {
    width: 100%;
    height: 100%;
  }
  .homegrid {
    top: 0;
    position: absolute;
    display: grid;
    height: 100%;
    width: 100vw;
    box-sizing: border-box;
    grid-template-rows: 3em 2em 2.2fr 1.6fr 2em 0.5fr;
    grid-template-columns: 1fr 1fr 2fr 2fr 1fr 1fr;
    grid-template-areas:
      "nav          .           logo        logo        .          .   "
      "art          art         art         art         art        art "
      "art          art         art         art         art        art "
      "art          art         art         art         art        art "
      "art          art         art         art         art        art "
      "cont         cont        cont        cont        cont       cont";
  }
  .control,
  .played-1,
  .played-2,
  .played-3,
  .played-4 {
    display: none;
  }

  .logo-mobile {
    display: block;
  }

  .art-mobile {
    grid-area: art;
    display: flex;
    align-items: center;
  }

  .art-mobile > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .nowplayingtext {
    display: none;
  }

  .desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    grid-column-start: 1;
    grid-column-end: span 6;
    grid-row-start: 4;
    grid-row-end: 6;
    text-align: center;
    animation: ease-in-desc 0.6s forwards;
    padding: 1vh;
  }

  .desc > p {
    animation-duration: 0.6s;
    animation: ease-in-desc-text 0.6s forwards;
    margin: 1vh;
  }

  .desc > a {
    animation-duration: 0.6s;
    animation: ease-in-desc-text 0.6s forwards;
    margin: 1vh;
  }

  h1 {
    font-size: 1em;
    line-height: 1em;
  }

  .logo-mobile {
    margin: 5px;
  }

  .cont {
    grid-area: cont;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    align-items: center;
    flex-grow: initial;
    padding-left: 1em;
    background: #82d7ff;
    box-shadow: $panel-drop;
    color: #334;
  }

  .cont > * {
    margin-right: 1em;
  }

  h1.artist {
    font-size: 1em !important;
  }

  h1.title {
    font-size: 2em !important;
  }

  h1.album {
    font-size: 1em !important;
  }

  .purchase a {
    justify-content: center;
    color: white;
    border: white solid 1px;
    border-radius: 1vh;
    background: #444;
  }

  .banner {
    display: none;
  }

  .nowplayingtext {
    display: none;
  }
  .dropdown-content {
    bottom: 8vh;
    font-size: 1rem;
  }
  .ioswarning {
    display: block;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    border-radius: 10px;
    padding: 0.5vh;
    text-align: center;
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 5;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .cont > .metadata {
    margin-top: 0;
    margin-bottom: auto;
    overflow: hidden;
    text-align: center;
  }

  .cont > .metadata > p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: none;
    white-space: nowrap;
  }
  .cont > .metadata > .arrow {
    font-weight: bold;
    color: white;
    margin-bottom: -2vh;
    font-size: 3vh;
  }
}
